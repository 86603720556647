<template>
  <div class="relative hero-products min-h-[31rem] lg:min-h-[38rem] overflow-hidden">
    <swiper class="absolute w-full h-full top-0 left-0" :options="swiperOption" ref="swiperEl">
      <swiper-slide
        v-for="product, index in data" :key="index" class="w-full flex-none flex flex-col justify-center items-center gap-6 lg:gap-16 px-4 md:px-0" :data-slide="index">
        <nuxt-img v-if="product.image.data" provider="sirvProvider" sizes="sm:600px lg:1600px" :src="product.image.data.attributes.url" :alt="product.Name" class="absolute w-full h-full top-0 left-0 -z-1 object-cover"/>
        <h2 v-if="product.Name" class="font-bold text-4xl font-serif text-primary lg:text-5xl text-center animate" data-animate-type="animate-transition-in">{{product.Name}}</h2>
        <p v-if="product.description" class="max-w-lg text-center text-black animate" data-animate-type="animate-fade-in">{{product.description}}</p>
        <div
          class="animate"  data-animate-type="animate-transition-in">
          <PrimaryButton
            v-if="product.link"
            :to="product.link">
              {{ $t('Order') }}
          </PrimaryButton>
        </div>
      </swiper-slide>
    </swiper>
    <div class="absolute top-10 right-10 z-1 gap-4 flex flex-row animate data-hero-products will-change-transform ease-in-out duration-1000" data-type="horizontal" data-animate-type="animate-fade-in" ref="swiperNav">
      <button v-for="product, index in data" type="button" class="font-bold text-xs text-lightGray" :class="index == 0 ? 'text-black' : ''" :key="index" @click="toSlide(index)" :data-slide-nav="index">{{product.Name}}</button>
    </div>
    <FlowerLoader ref="loader" icon="15Flower" width="130" height="257" class="absolute bottom-10 right-10 opacity-50 animation-stop data-hero-products will-change-transform ease-in-out duration-1000" data-volacity="400"/>
  </div>
</template>
<style lang="scss">
  .hero-products {
    .swiper-wrapper {
      display: flex;
      width: 100%;
      height: 100%;
    }
  }
  .animation{
    &-stop {
      svg {
        animation-play-state: paused!important;
      }
    }
    &-run {
      svg {
        animation-play-state: running;
      }
    }
  }
</style>
<style lang="scss" scoped>
  .animate {
    opacity: 0;
  }
</style>
<script>
  import { defineComponent } from '@vue/composition-api'
  import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
  import PrimaryButton  from '~/components/Headless/PrimaryButton.vue';
  import FlowerLoader from '~/components/Headless/FlowerLoader.vue';
  import useParallax from '~/helpers/headless/useParallax.ts';
  import { animateOnScroll } from '~/helpers/headless/helpers';

  export default defineComponent({
    name: 'HeadlessHeroProducts',
    components: {
      Swiper,
      SwiperSlide,
      PrimaryButton,
      FlowerLoader
    },
    props: {
      data: {
        type: [Object, Array],
        default: []
      },
      products: {
        type: [Object, Array],
        default: () => [
          {
            Name: 'Seasonal Bouquet',
            description: 'A beautiful hand-tied bouquet put together with a selection of fresh seasonal flowers  expertly chosen by our Chichester florists.',
            link: '/seasonal-bouquet',
            image: '/homepage/seasonal-bouquet.png'
          }
        ]
      },
      swiperOption: {
        type: Object,
        default() {
          return {
            slidesPerView: 1,
            centeredSlides: true,
            autoplay: {
              delay: 5000
            }
          }
        }
      }
    },
    methods: {
      toSlide(index) {
        this.$refs.swiperEl.$swiper.slideTo(index, 0);
        const buttons = this.$refs.swiperNav.querySelectorAll('button');
        buttons.forEach(button => {
          const attribute = button.getAttribute('data-slide-nav');
          if (attribute == index) {
            button.classList.add('text-black');
          } else {
            button.classList.remove('text-black');
          }
        })
      },
    },
    mounted() {
      const parentRef = this.$refs.swiperEl;
      parentRef.$swiper.on('slideChange', () => {
        const active = parentRef.$el.querySelector('.swiper-slide-active')?.getAttribute('data-slide');
        const buttons = this.$refs.swiperNav.querySelectorAll('button');
        buttons.forEach(button => {
          const attribute = button.getAttribute('data-slide-nav');
          if (attribute == active) {
            button.classList.remove('text-black');
          } else {
            button.classList.add('text-black');
          }
        })
      });
      this.$refs.loader.$el.classList.replace('animation-stop', 'animation-run');
      useParallax('data-hero-products', 1, true);
      animateOnScroll();
    },
    unmounted() {
      useParallax('data-hero-products', 1, false);
    }
  })
</script>
