<template functional>
  <div class="block">
    <template v-if="props.link">
      <a :href="props.link" class="group relative inline-block lg:min-w-button">
        <span :class="props.classNames">
          <slot />
        </span>
      </a>
    </template>
    <template v-else-if="props.to">
      <NuxtLink
      class="group relative inline-block lg:min-w-button"
      :to="props.to">
        <span :class="props.classNames">
          <slot />
        </span>
      </NuxtLink>
    </template>
    <template v-else>
      <button class="group relative inline-block lg:min-w-button"
        v-bind="data.attrs"
        v-on="!props.disabled ? listeners : {}"
        :type="props.type">
        <span :class="props.classNames">
          <slot />
        </span>
      </button>
    </template>
  </div>
</template>
<script>
  import { defineComponent } from '@nuxtjs/composition-api';

  export default defineComponent({
    name: "SecondaryButton",
    props: {
      classNames: {
        type: String,
        default: 'duration-100 ease-linear bg-grayLightAccent text-grey-800 rounded-2xl font-bold uppercase py-3 px-8 block text-center group-hover:shadow-md group-hover:rounded-t-none'
      },
      link: {
        type: String,
        default: ''
      },
      to: {
        type: String,
        default: ''
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      type: {
        type: String,
        default: 'button'
      }
    }
  });
</script>
