<template>
  <div class="" >
      <SvgImage
        :animate="animateProp"
        animation="animate-draw-flowers-alternate"
        offset="1000" :icon="icon" :width="width" :height="height" ref="icon" class="block"/>
  </div>
</template>
<style lang="scss">

</style>
<script>
  import { defineComponent } from '@vue/composition-api';
  import SvgImage from '~/components/General/SvgImage.vue';

  export default defineComponent({
    name: 'FlowerLoader',
    components: {
      SvgImage
    },
    props: {
      icon: {
        type: String,
        default: '17Flower'
      },
      width: {
        type: String,
        default: '0'
      },
      height: {
        type: String,
        default: '0'
      },
      background: {
        type: String,
        default: ''
      },
      animateProp: {
        type: Boolean,
        default: true
      }
    },
  })
</script>
