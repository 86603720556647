import type { MetaInfo } from 'vue-meta';

export const getMetaInfo = (page: any, isNoIndex: boolean = false): MetaInfo => {
  if (!page) {
    return null;
  }

  const seoTags: MetaInfo = {
    meta: [],
  };

  if (page?.meta_title || page?.title || page?.name) {
    seoTags.title = page?.meta_title || page?.title || page?.name;
    seoTags.title = seoTags.title + ' | Hedge Rose';
    seoTags.meta.push({
      hid: 'og:title',
      property: 'og:title',
      content: seoTags.title
    });
  }
  if (page?.meta_description) {
    seoTags.meta.push({
      hid: 'description',
      name: 'description',
      content: page.meta_description,
    });
    seoTags.meta.push({
      hid: 'og:description',
      property: 'og:description',
      content: page.meta_description
    });
  } else {
    seoTags.meta.push({
      hid: 'og:description',
      property: 'og:description',
      content: 'Hedge Rose - Chichester Florists'
    });
  }
  if (page?.meta_keyword || page?.meta_keywords) {
    seoTags.meta.push({
      hid: 'keywords',
      name: 'keywords',
      content: page?.meta_keyword || page?.meta_keywords,
    });
  }
  if (isNoIndex) {
    seoTags.meta.push({
      name: 'robots',
      content: 'noindex, nofollow',
    });
  }
  seoTags.meta.push({
    hid: 'og:image',
    property: 'og:image',
    content: page.og_image ? page.og_image : '/icon.png'
  });

  return seoTags;
};
