<template>
  <div class="relative h-full">
      <img sizes="sm:100vw md:50vw lg:700px" :alt="current.text" :src="current.image" class="w-full h-full object-cover" />
      <span class="absolute right-4 lg:right-8 bottom-4 lg:bottom-8 text-xs text-white">{{current.text}}</span>
      <nuxt-link v-if="current.link" :to="current.link" class="absolute top-0 w-full h-full"/>
  </div>
</template>
<style lang="scss" scoped>
  span {
    text-shadow: 1px 1px 15px #666;
  }
</style>
<script>
  import { defineComponent } from '@vue/composition-api'

  export default defineComponent({
    name: 'HeroImage',
    props: {
      images: {
        type: [Object, Array],
        default: null
      }
    },
    setup(props) {
      let current = {};

      if (props.images.length > 1) {
        props.images.forEach((image, index) => {
          const storage = process.browser ? localStorage.getItem('heroImage') : null;

          if (!storage && index == 0) {
            current.image = image.image;
            current.text = image.text;
            current.index = index;
            current.link = image.to;
          }

          if (storage && storage == index) return;
          current.image = image.image;
          current.text = image.text;
          current.index = index;
          current.link = image.to;
        });
      } else {
        current.image = props.images[0].image;
        current.text = props.images[0].text;
        current.index = '0';
        current.link = props.images[0].to;
      }

      if (process.browser) {
        localStorage.setItem("heroImage", current.index);
      }

      return {
        current
      }
    },
  })
</script>
