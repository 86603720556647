<template>
  <section class="pt-64 pb-16 flex relative bg-secondary overflow-hidden">
    <ul class="flex absolute z-0 justify-center w-full h-full top-0 items-center list-none pl-0">
      <li v-for="image, index in setLimitImages" :key="index" class="absolute animate" data-animate-type="animate-scale-in" >
        <nuxt-img provider="sirvProvider" :src="image.attributes.url" class="w-full h-full object-cover data-wedding-scroll duration-1000 ease-in-out will-change-transform" :data-volacity="`${index + 1}00`" :alt="title"/>
      </li>
    </ul>
    <div class="flex flex-col items-center justify-center w-full grow z-1 relative">
      <h2 class="font-serif font-bold text-[4.375rem] md:text-8xl leading-tight text-center text-primary animate max-w-lg" data-animate-type="animate-fade-in" data-scroll data-scroll-speed="2" v-if="data.Title">{{data.Title}}</h2>
      <p v-if="data.Content" class="text-center px-7 font-medium pt-24 pb-12 max-w-2xl animate" data-animate-type="animate-fade-in" v-html="$md.render(data.Content)"></p>
      <PrimaryButton v-if="data.buttonurl && data.button" :to="data.buttonurl" class="animate" data-animate-type="animate-transition-in">{{data.button}}</PrimaryButton>
    </div>
  </section>
</template>
<style lang="scss" scoped>
  h2, p {
    opacity: 0;
  }
  ul {
    li {
      transform: scale(0);
      &:first-child {
        top: 7rem;
        height: 13rem;
        width: 8.75rem;
        right: 18rem;
        @media (min-width: 767px) {
          right: auto;
          left: 5rem;
          top: 5rem
        }
        @media (min-width: 1440px) {
          width: 10rem;
          height: 14rem;
        }
      }
      &:nth-child(2){
        top: 5rem;
        height: 12rem;
        width: 7rem;
        right: auto;
        left: auto;
        @media (min-width: 767px) {
          top: 2rem;
          height: 14rem;
          width: 9rem;
        }
        @media (min-width: 1440px){
          right: 5rem;
        }
      }
      &:nth-child(3) {
        top: 11rem;
        height: 16rem;
        width: 10rem;
        right: -5rem;
        @media (min-width: 767px) {
          top: 20rem;
        }
        @media (min-width: 1024px) {
          right: 4rem;
          width: 14rem;
          height: 11rem;
        }
        @media (min-width: 1440px){
          width: 15rem;
          height: 10rem;
        }
      }
      &:nth-child(4) {
        top: 2rem;
        height: 7rem;
        width: 6rem;
        right: -2rem;
        @media (min-width: 767px) {
          height: 11rem;
          width: 10rem;
        }
        @media (min-width: 1024px) {
          right: 2rem;
          width: 13rem;
          height: 10rem;
        }
        @media (min-width: 1440px) {
          width: 16rem;
          height: 11rem;
          right: auto;
          left: auto;
        }
      }
      &:nth-child(5) {
        top: 35rem;
        height: 8rem;
        width: 6rem;
        right: 17rem;
        @media (min-width: 767px) {
          top: 25rem;
          height: 16rem;
          width: 12rem;
          right: auto;
          left: -5rem;
        }
        @media (min-width: 1024px) {
          left: 4rem;
        }
        @media (min-width: 1440px) {
          left: 10rem;
        }
      }
      &:nth-child(6) {
        bottom: 1rem;
        height: 10rem;
        width: 7.75rem;
        right: 19rem;
        @media (min-width: 767px) {
          right: auto;
          left: 5rem
        }
        @media (min-width: 1024px) {
          left: 1rem;
          width: 11rem;
          height: 16rem;
        }
        @media (min-width: 1440px) {
          left: 5rem;
          bottom: 5rem;
        }
      }
      &:nth-child(7) {
        bottom: 2.5rem;
        height: 13rem;
        width: 8.75rem;
        right: -5rem;
        @media (min-width: 767px) {
          bottom: 1.5rem;
          right: -1rem
        }
        @media (min-width: 1024px) {
          bottom: 2rem;
          right: 4rem;
        }
        @media (min-width: 1440px){
          width: 11rem;
          height: 16rem;
          right: 10rem;
          bottom: 9rem;
        }
      }
    }
  }
</style>
<script>
  import { defineComponent } from '@vue/composition-api';
  import PrimaryButton from '~/components/Headless/PrimaryButton.vue';
  import useParallax from '~/helpers/headless/useParallax.ts';

  export default defineComponent({
    name: 'HeadlessHeroWedding',
    components: {
      PrimaryButton
    },
    props: {
      data: {
        type: Object,
        default: {}
      },
      title: {
        type: String,
        default: 'OUR WEDDING FLOWERS WORK',
      },
      text: {
        type: String,
        default: 'Congratulations you are getting married!<br/><br/>At Hedge Rose florists, we take great pride in selecting only the freshest and best quality of flowers for your special day. We will draw on our extensive experience to guide you to the best in-season flowers.<br/><br/>With over 17 years of experience, no two weddings are the same, making every wedding exclusively unique. Creating textures and working to your theme; we will make decorations your guests will talk about for years to come. From buttonholes to floral arches we cover all aspects of floristry.',
      },
      images: {
        type: Array,
        default: null
      },
      link: {
        type: String,
        default: '/'
      },
      linkText: {
        type: String,
        default: 'Get Started'
      }
    },
    computed: {
      setLimitImages() {
        return this.data.images?.data.slice(0, 7);
      }
    },
    mounted() {
      useParallax('data-wedding-scroll', 1, true);
    },
    unmounted() {
      useParallax('data-wedding-scroll', 1, false);
    }
  })
</script>
