const useParallax = (selector: string, level = 1, mount: boolean): void => {
  const elements = document.getElementsByClassName(selector) as HTMLCollectionOf<HTMLElement>
  if (!elements) {
    return
  }
  const handleScroll = () => {
    Array.from(elements).forEach((element) => {
      const top = element.getBoundingClientRect().top
      const volacity = element.dataset.volacity ? parseInt(element.dataset.volacity, 10) : 1;
      const type = element.dataset.type
      const coordinates = `${(top / ((18 + volacity) % 3) / -25)/level}%` // to do : find a better formula
      const scale =
        top <= window.innerHeight * 0.7
          ? 1
          : Math.min((window.innerHeight * 0.7) / top, 1);

      const percentage = window.scrollY / (element.offsetTop + element.offsetHeight) * 100;

    requestAnimationFrame(() => {
        switch (type) {
          case 'horizontal':
            element.style.transform = `translateX(${coordinates})`
            break;
          case 'scale':
            element.style.transform = `scale(${scale})`
            break;
          case 'circle-clip':
            element.style.clipPath  = `circle(${100 - percentage}%)`
            break;
          default:
            element.style.transform = `translateY(${coordinates})`
        }
      })
    })
  }
  if (mount == true) {
    return window.addEventListener('scroll', handleScroll)
  } else {
    return window.removeEventListener('scroll', handleScroll)
  }
}

export default useParallax
