<template>
  <div class="bg-primary animate"  data-animate-type="animate-fade-in" v-if="text">
    <p data-type="horizontal" data-volacity="500" class="text-white p-4 text-[18px] font-semibold text-center data-tel-scroll will-change-transform duration-1000 ease-in-out">
      {{text}}
      <a v-if="urlText" :href="url" class="text-white hover:opacity-50">{{urlText}}</a>
    </p>
  </div>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'
  import useParallax from '~/helpers/headless/useParallax.ts';

  export default defineComponent({
    name: 'HeadlessTelephoneBar',
    props: {
      text: {
        type: String,
        default: null
      },
      url: {
        type: String,
        default: null
      },
      urlText: {
        type: String,
        default: null
      }
    },
    mounted() {
      useParallax('data-tel-scroll', 1, true);
    },
    unmounted() {
      useParallax('data-tel-scroll', 1, false);
    }
  });
</script>

