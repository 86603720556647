<template>
  <div class="flex flex-col items-left text-primary max-w-[441px] mb-[88px] data-reason-scroll duration-700 ease-linear will-change-transform" data-type="scale" data-volacity="200">
    <div class="self-center mb-8 animate" data-animate-type="animate-transition-in">
      <img :src="'homepage/' + content.svg  + '.svg'" :alt="content.title" width="200" height="200" />
    </div>
    <div>
      <h2 class="font-bold text-primary text-2xl md:text-[36px] leading-[48px] animate" data-animate-type="animate-fade-in">{{content.title}}</h2>
    </div>
    <div>
      <p class="font-medium text-black animate delay-75"  data-animate-type="animate-fade-in">{{content.text}}</p>
    </div>
  </div>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'
  import SvgImage from '~/components/General/SvgImage.vue';
  import useParallax from '~/helpers/headless/useParallax.ts';

  export default defineComponent({
    name: 'HeadlessReasonBlock',
    components: { SvgImage },
    props: {
      content: {
        type: Object,
        default: null
      }
    },
    mounted() {
      useParallax('data-reason-scroll', 1, true);
    },
    unmounted() {
      useParallax('data-reason-scroll', 1, false);
    }
  });
</script>
