













































import {
  defineComponent,
  useContext,
  onMounted,
  useAsync,
  onServerPrefetch
} from '@nuxtjs/composition-api';
import { useCache, CacheTagPrefix } from '@vue-storefront/cache';
import LoadWhenVisible from '~/components/utils/LoadWhenVisible.vue';
import HeadlessWhyUs from '~/components/Headless/WhyUs/WhyUs.vue';
import HeadlessTelephoneBar from '~/components/Headless/TelephoneBar.vue';
import HeadlessTwoColBanner from '~/components/Headless/TwoColBanner.vue';
import HeadlessHero from '~/components/Headless/Hero.vue';
import HeadlessHeroProducts from '~/components/Headless/HeroProducts.vue';
import HeadlessReviews from '~/components/Headless/Reviews.vue';
import HeadlessHeroWedding from '~/components/Headless/HeroWedding.vue';
import { getMetaInfo } from '~/helpers/getMetaInfo';
import { animateOnScroll, setSirvImage } from '~/helpers/headless/helpers';
import HeadlessExternalPartners from '~/components/Headless/ExternalPartners.vue';
import FlowersSprite from '~/components/General/FlowersSprite.vue';

export default defineComponent({
  name: 'HomePage',
  components: {
    LoadWhenVisible,
    HeadlessWhyUs,
    HeadlessTelephoneBar,
    HeadlessTwoColBanner,
    HeadlessHero,
    HeadlessHeroProducts,
    HeadlessReviews,
    HeadlessHeroWedding,
    NewProducts: () => import(/* webpackPrefetch: true */ '~/components/NewProducts.vue'),
    HeadlessExternalPartners,
    FlowersSprite
  },
  layout: 'homeLayout',
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  setup() {
    const { addTags } = useCache();
    const { app, $strapi } = useContext();
    const page = {
        title: "Chichester Florist's | Flower Delivery",
        meta_description: "Our breath-taking tailor-made bouquets are comprised of the freshest and most beautiful flowers - all handcrafted in the cathedral city of Chichester. Everything we make is unique - expect luxury arrangements for all of life's big events and special moments.",
        og_image: ''
      };

    onServerPrefetch(async () => {
      addTags([{ prefix: CacheTagPrefix.View, value: 'home' }]);
    });

    // Fetch Strapi data
    const qs = require('qs');
    const query = qs.stringify({
      populate: [
        'Banner',
        'Banner.Navigation',
        'Banner.primarybutton',
        'Banner.secondarybutton',
        'Banner.Images.image.media',
        'Banner.Images.image.sharedMedia',
        'wedding',
        'wedding.images.media',
        'HeroTwoCol',
        'HeroTwoCol.Images.media',
        'ShopTwoCol',
        'ShopTwoCol.Images',
        'ShopTwoCol.Images.media',
        'whyus',
        'whyus.stickycol',
        'Products',
        'Products.Product.image.media',
        'partner',
        'partner.image.media'
      ],
    }, {
      encodeValuesOnly: true, // prettify URL
    });

    const homeData = useAsync(async () => await $strapi.find('homepage?' + query));

    onMounted(() => {
      animateOnScroll();
    });

    return {
      homeData,
      page,
      setSirvImage
    };
  },
  data() {
    return {
      events: [],
      error: null,
      banner1: {
        topText: 'WORKSHOPS',
        titleText: 'Christmas Wreath',
        textMain: 'You will be guided through step-by-step in our workshop to create your very own gorgeous Christmas door wreath.',
        button: {
          to: '/join',
          text: 'Join'
        },
        images: ['/homepage/two-col-placeholder.png'],
        reverse: true
      },
      banner2: {
        topText: '11 The Hornet, PO19 7JL',
        titleText: 'Check out our Flower Shop in Chichester',
        textMain: 'Monday to Friday 9am - 5pm and Saturday 9am - 4pm ',
        button: {
          to: '/contact-us',
          text: 'Directions'
        },
        images: ['/homepage/two-col-placeholder.png', '/homepage/hero-placeholder.jpg']
      },
      hero: {
        images: [
          {
            image: '/homepage/hero-placeholder.jpg',
            text: 'Spring Workshop in March',
            to: '/shop',
            order: 1
          },
          {
            image: '/homepage/two-col-placeholder.png',
            text: 'Winter Workshop in January',
            to: '',
            order: 2
          },
          {
            image: '/homepage/hero-placeholder.jpg',
            text: 'Visit our shop',
            to: '/shop',
            order: 3
          },
        ]
      },
      wedding: [
        'homepage/w-1.png',
        'homepage/w-2.png',
        'homepage/w-3.png',
        'homepage/w-4.png',
        'homepage/w-5.png',
        'homepage/w-6.png',
        'homepage/w-7.png',
      ]
    }
  },
  head() {
    return getMetaInfo(this.page);
  },
});
