<template>
    <div class="flex justify-center lg:justify-between max-w-5xl m-auto flex-wrap lg:flex-row px-5 lg:px-0 py-5">
        <div class="text-center py-4" v-for="item, index in partners" :key="index">
          <span v-if="item.text" class="block text-primary text-lg pb-3">{{item.text}}</span>
          <nuxt-img v-if="item.image.data.attributes.url" :alt="item.text" class="m-auto max-w-xs max-h-40 w-full" provider="sirvProvider" width="320" :src="item.image.data.attributes.url" />
        </div>
    </div>
</template>
<script>
  import { defineComponent } from '@vue/composition-api'
  import SecondaryButton  from '~/components/Headless/Button.vue';

  export default defineComponent({
    name: 'HeadlessExternalPartners',
    components: {
      SecondaryButton
    },
    props: {
      partners: {
        type: [Object, Array],
        default: () => ([
          {
            text: 'As seen in Magazine test',
            image: '/homepage/brides-magazine-logo.webp'
          },
          {
            text: 'As seen in Magazine test',
            image: '/homepage/brides-magazine-logo.webp'
          }
        ]),
      }
    },
  })
</script>
