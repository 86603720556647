<template>
  <section class="md:grid md:grid-cols-2 md:gap-32 mx-[30px] md:ml-[152px] md:mr-0 py-6 lg:py-12 xl:py-20" id="why-us">
    <div class="md:order-last mt-4 md:mt-16 relative">
      <p class="text-primary font-serif font-bold text-[36px] leading-[46px] md:text-[50px] md:leading-[56px] max-w-[376px] md:max-w-[441px] sticky top-6 animate" data-animate-type="animate-fade-in" data-scroll>{{data.Title}}</p>
      <HomeWatermark class="hidden md:block absolute right-0 bottom-0 data-why-scroll duration-1000 ease-in-out will-change-transform" />
      <CircleGradient class="hidden md:block absolute right-0 bottom-0 -z-1 data-why-scroll duration-1000 ease-in-out will-change-transform" data-type="horizontal"/>
    </div>
    <div>
      <HeadlessReasonBlock v-for="block in data.stickycol" :key="block.id" :content="block"/>
    </div>
  </section>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'
  import SvgImage from '~/components/General/SvgImage.vue';
  import HeadlessReasonBlock from '~/components/Headless/WhyUs/ReasonBlock.vue';
  import CircleGradient from '~/components/Headless/Watermarks/CircleGradient.vue';
  import HomeWatermark from '~/components/Headless/Watermarks/HomeWatermark.vue';
  import useParallax from '~/helpers/headless/useParallax.ts';

  export default defineComponent({
    name: 'HeadlessWhyUs',
    components: {
      SvgImage, HeadlessReasonBlock, CircleGradient, HomeWatermark
    },
    props: {
      data: {
        type: Object,
        required: true,
        default: () => ({})
      }
    },
    data() {
      return {
        pageTitle: "We deliver flowers, so why choose us?",
        first: {
          icon: "it_is_flower",
          title: "It is what it is",
          description: "Our local florist will make exactly what you see on your screen for your order... or your money back!"
        },
        second: {
          icon: "handcrafted_flower",
          title: "Handcrafted",
          description: "Hedge Rose floral designs are handcrafted in the cathedral city of Chichester."
        },
        third: {
          icon: "care_flower",
          title: "We really really care",
          description: "Every order we have in a way is like a flower, it has to be gently handled, there is that joyful emotion and it has to be perfect. "
        },
      }
    },
    mounted() {
      useParallax('data-why-scroll', 1, true);
    },
    unmounted() {
      useParallax('data-why-scroll', 1, false);
    }
  });
</script>
