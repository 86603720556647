<template>
    <section class="flex flex-col md:flex-row items-center justify-between py-12 xl:py-20 px-7 lg:px-30 xl:px-44 relative gap-10 md:gap-0 reviews-section">
      <div class="flex flex-col items-center lg:w-1/2 animate" data-animate-type="animate-fade-in" data-scroll data-scroll-speed="0.5" data-scroll-direction="horizontal">
        <h2 class="text-2xl font-bold lg:text-4xl text-primary text-center pb-7 lg:pb-14 max-w-md">{{title}}</h2>
      </div>
      <FlowerLoaderDefault />
      <swiper class="overflow-hidden lg:w-1/2 animate" data-animate-type="animate-fade-in" :options="swiperOption" data-scroll data-scroll-speed="-0.5"  data-scroll-direction="horizontal">
        <swiper-slide
          v-for="review, index in reviews" :key="index" class="w-full flex-none relative py-4 cursor-pointer" :data-slide="index">
          <p v-if="review.description" v-html="review.description" class="font-medium text-center max-w-md m-auto pb-6 lg:pb-10"></p>
          <p v-if="review.author" class="text-xs font-bold text-center text-primary">{{review.author}}</p>
          <FlowerLoader icon="17Flower" width="130" height="257" class="absolute flex align-middle justify-center inset-0 m-auto opacity-20 -z-1"/>
        </swiper-slide>
      </swiper>
      <SvgImage
        :animate="animateProp"
        animation="animate-draw-flowers"
        offset="1000" icon="9Flower" width="77" height="132"
        class="absolute left-2 md:left-4 lg:left-20 top-24 lg:top-44 data-reviews-scroll ease-in-out duration-1000 will-change-transform" data-volacity="100"/>
      <CircleGradient class="absolute right-0 bottom-0 -z-1" />
      <CircleGradient class="absolute -left-60 top-auto right-auto -z-10" />
    </section>
</template>
<style lang="scss">
  .reviews-section {
    .swiper-wrapper {
      display: flex;
      width: 100%;
      height: 100%;
    }
    .animate-draw-flowers-alternate {
      animation-play-state: paused!important;
    }
    .swiper-slide-active {
      .animate-draw-flowers-alternate {
        animation-play-state: running!important;
      }
    }
  }
</style>
<style lang="scss" scoped>
  .animate {
    opacity: 0;
  }
</style>
<script>
  import { defineComponent } from '@vue/composition-api';
  import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
  import SvgImage from '~/components/General/SvgImage.vue';
  import CircleGradient from '~/components/Headless/Watermarks/CircleGradient.vue';
  import FlowerLoader from '~/components/Headless/FlowerLoader.vue';
  import useParallax from '~/helpers/headless/useParallax.ts';
  import FlowerLoaderDefault from '~/components/General/FlowerLoaderDefault.vue';
  export default defineComponent({
    name: 'HeadlessReviews',
    components: {
      Swiper,
      SwiperSlide,
      SvgImage,
      CircleGradient,
      FlowerLoader,
      FlowerLoaderDefault,
    },
    props: {
      title: {
        type: String,
        default: 'Hear what our happy customers have to say'
      },
      reviews: {
        type: [Object, Array],
        default: () => [
          {
            author: 'Marry Butterworth',
            description: "The flowers I received this morning for Mother's Day are really special.  They look so charming in the jampot and smelled divine.   When they arrived I thought they had been freshly picked specially for me and I didn't actually realise they came from a florist. They are really lovely and I should thank Hedge Rose as well as my daughter for these!",
            link: '',
          },
          {
            author: 'Julie Newman',
            description: "Just received my 2nd delivery of absolutely beautiful flowers from Hedge Rose Chichester, thank you for delivering out of hours to. They're stunning!",
            link: '',
          },
        ]
      },
      animateProp: {
        type: Boolean,
        default: true
      }
    },
    setup() {
      const swiperOption = {
        slidesPerView: 1,
        centeredSlides: true,
        autoplay: {
          delay: 10000
        }
      }
      return {
        swiperOption
      }
    },
    mounted() {
      useParallax('data-reviews-scroll', 1, true);
    },
    unmounted() {
      useParallax('data-reviews-scroll', 1, false);
    }
  })
</script>
