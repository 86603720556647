<template>
  <div class="flex flex-col md:flex-row xl:min-h-[88vh] hero">
    <div class="p-8 xl:pl-24 xl:pr-12 relative md:w-3/5 xl:w-7/12 xl:flex xl:flex-col xl:justify-center">
      <h1 data-volacity="175" v-html="$md.render(titleMarked)" class="font-bold pb-4 xl:pb-6 2xl:pb-8 font-serif text-4xl xl:text-5xl 2xl:text-6xl min-[1920px]:text-7xl min-[2000px]:text-[5rem] min-[2000px]:leading-[5.6rem] data-hero-scroll will-change-transform ease-in-out"></h1>
      <div data-volacity="50" class="py-4 xl:py-6 2xl:py-8 flex flex-col xl:flex-row xl:gap-3 hero-nav data-hero-scroll will-change-transform ease-in-out">
          <span v-for="item, index in navigation" :key="index">
              <span v-if="index != 0" class="w-0.5 h-2.5 mr-3 bg-black hidden xl:inline-block"></span>
              <nuxt-link class="font-bold text-black hover:text-secondaryLight transition-colors" :to="item.Link">{{ item.Text }}</nuxt-link>
          </span>
      </div>
      <div data-volacity="25" class="pt-4 pb-8 xl:py-6 2xl:py-8 flex flex-wrap justify-between lg:justify-start lg:gap-x-8 hero-buttons data-hero-scroll will-change-transform ease-in-out">
        <PrimaryButton
          v-if="data.primarybutton"
          :to="data.primarybutton.link"
          type="button">
            {{data.primarybutton.text}}
        </PrimaryButton>
        <PrimaryButton
          v-if="data.secondarybutton"
          :to="data.secondarybutton.link"
          :tulip="pinkTulip"
          classNames="relative ease-linear bg-grayLightAccent text-grey-800 rounded-2xl font-bold uppercase py-3 px-8 block text-center group-hover:shadow-md group-hover:rounded-t-none">
            {{data.secondarybutton.text}}
        </PrimaryButton>
      </div>
      <EarliestDelivery class="max-w-[11rem] md:max-w-[15rem] lg:max-w-[20rem] xl:max-w-[33rem] font-semibold block xl:pt-6 2xl:pt-8 hero-delivery" />
      <HeroFlower />
      <SvgImage
        icon="20Flower"
        width="144" height="298"
        :animate="false"
        class="absolute -bottom-3 right-0 md:right-5 xl:bottom-auto xl:top-14 2xl:top-12 min-[1920px]:top-24 -z-10 xl:right-0 xl:left-0 xl:justify-center xl:opacity-60 hero-flower data-hero-scroll will-change-transform ease-in-out" data-volacity="200" data-type="horizontal" />
      <CircleGradient class="absolute -left-60 bottom-0 lg:left-0 -z-1 data-hero-scroll will-change-transform" data-volacity="200" data-type="horizontal"/>
    </div>
    <div class="md:w-2/5 xl:w-5/12">
      <div data-volacity="100" data-type="circle-clip" class="relative h-full data-hero-scroll will-change-auto ease-in-out">
          <nuxt-img provider="sirvProvider" preload sizes="sm:100vw md:50vw lg:700px" :alt="setCurrentImage.alt" :src="setCurrentImage.src" class="w-full h-full object-cover" />
          <span class="absolute right-4 lg:right-8 bottom-4 lg:bottom-8 text-xs text-white">{{setCurrentImage.alt}}</span>
          <nuxt-link :to="setCurrentImage.to" aria-label="Banner Image Link" class="absolute top-0 w-full h-full"/>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
  .hero {
    h1 {
      > strong {
        color: var(--c-primary);
        &:first-child {
          color: #EDD3CA;
        }
      }
      a {
        strong {
          color: var(--c-primary);
        }
        &:first-child {
          strong {
            color: #EDD3CA;
          }
        }
        &:hover {
          opacity: .75;
        }
      }
    }
    .animate-clipath {
      clip-path: circle(100%);
    }
  }
</style>
<script>
  import { defineComponent } from '@vue/composition-api'
  import PrimaryButton  from '~/components/Headless/PrimaryButton.vue';
  import SecondaryButton  from '~/components/Headless/Button.vue';
  import EarliestDelivery from '~/components/Headless/EarliestDelivery.vue'
  import HeroImage from '~/components/Headless/HeroImage.vue';
  import SvgImage from '~/components/General/SvgImage.vue';
  import CircleGradient from '~/components/Headless/Watermarks/CircleGradient.vue';
  import useParallax from '~/helpers/headless/useParallax.ts';
  import HeroFlower from '~/components/General/HeroFlower.vue';

  export default defineComponent({
    name: 'HeadlessHero',
    components: {
      PrimaryButton,
      SecondaryButton,
      EarliestDelivery,
      HeroImage,
      SvgImage,
      CircleGradient,
      HeroFlower
    },
    data() {
      return {
        pinkTulip: `<svg xmlns="http://www.w3.org/2000/svg" width="32" height="52" viewBox="0 0 32 52" fill="none">
          <path d="M14.0064 51.3105V32.6931H16.4213V51.3105C16.4213 51.3105 16.4213 52 15.2448 52C14.0683 52 14.0064 51.3105 14.0064 51.3105Z" fill="#3E4E39"/>
          <path d="M6.23859 30.5067V26.774C5.7861 19.3998 7.0435 15.7047 12.7587 10.17C13.6765 9.36767 14.2293 8.94353 15.3345 8.23931C15.3345 8.23931 13.5636 7.14525 10.4243 5.98684C7.28498 4.82842 5.03111 4.50664 5.03111 4.50664C3.66053 4.25941 2.96241 4.177 1.8113 4.12056C1.38241 4.13175 1.17456 4.1733 0.845411 4.31363C0.452322 4.54513 0.267802 4.6961 0.0404504 5.02155C-0.0129137 5.24716 -0.0140503 5.38693 0.0404504 5.66512L0.120946 6.05126L0.281936 6.50175L0.684413 7.66017L1.00639 8.68987L1.16738 9.39779L1.32838 10.2344L1.40887 11.0067V14.8681L1.24788 15.9621L1.08689 16.7344L0.764908 18.0215L0.523422 18.7938C0.523422 18.7938 0.362386 19.6304 0.362386 19.6947C0.362386 19.7591 0.201441 20.7889 0.201441 20.7889V22.0116L0.362432 23.1057L0.684413 24.1998L1.24783 25.4868C1.51807 25.9348 1.69363 26.2157 2.05283 26.774C2.05283 26.774 2.53576 27.4175 2.77729 27.675C3.01882 27.9325 3.17972 28.1254 3.17972 28.1254L3.58224 28.5116L4.14567 29.0264L4.78967 29.5413L6.23859 30.5067Z" fill="#F4E2DC"/>
          <path d="M9.73997 32.0513C7.96907 31.5305 6.15835 30.42 6.15835 30.42V26.7839C6.15835 26.7839 5.99567 23.3425 6.32103 20.6803C6.64639 18.0182 8.43587 15.0313 8.43587 15.0313C8.43587 15.0313 9.41195 13.3431 11.3641 11.2653C13.3163 9.18755 15.3498 8.01879 15.3498 8.01879C15.3498 8.01879 16.5699 7.30455 18.6847 6.39552C20.7996 5.48648 22.4264 5.03196 22.4264 5.03196C22.4264 5.03196 22.8331 4.83717 24.1345 4.57745C25.436 4.31772 25.924 4.25279 25.924 4.25279C25.924 4.25279 26.9814 4.12293 27.5508 4.058L29.259 3.86321C29.259 3.86321 29.6657 3.86321 30.0724 4.058C30.4791 4.25279 30.7407 4.52739 30.7407 4.52739C30.7981 4.57753 30.9803 4.74951 31.0484 4.96703C31.1298 5.22676 30.9671 5.48648 30.9671 5.48648C30.9671 5.48648 30.5604 6.33058 30.235 7.23962C29.9097 8.14865 29.8283 8.6681 29.8283 8.6681C29.6657 9.57714 29.503 11.59 29.503 12.564V13.6678C29.503 13.7977 29.6657 14.7716 29.6657 14.7716L29.8283 15.8105L29.991 16.6546L30.235 17.6935L30.4791 18.6025L30.6417 19.7713C30.6417 19.7713 30.7231 21.4595 30.3977 23.4724C30.0724 25.4852 28.8523 26.7189 28.8523 26.7189C28.8523 26.7189 28.0929 27.9325 26.322 29.4127C24.5511 30.8929 22.7517 31.5238 22.7517 31.5238C22.7517 31.5238 21.7757 31.9134 20.1489 32.3679C18.5221 32.8224 17.2206 32.8874 17.2206 32.8874C17.2206 32.8874 16.5699 32.9523 15.4311 32.9523C14.2924 32.9523 13.6416 32.8874 13.6416 32.8874C13.6416 32.8874 11.5109 32.5721 9.73997 32.0513Z" fill="#EDD3CA"/>
          <path d="M4.99097 4.50671C4.99097 4.50671 5.15196 4.05622 5.15196 2.70473C5.15196 1.35325 5.31295 0.774042 5.31295 0.774042C5.31295 0.774042 5.59469 0.452258 5.95691 0.259191C6.31914 0.0661233 6.76187 0.0661215 6.76187 0.0661215C6.76187 0.0661215 7.56682 0.00176512 8.77425 0.130478C9.98168 0.259191 10.7061 0.45226 10.7061 0.45226C10.7061 0.45226 11.9136 0.709686 13.2015 1.16018C14.4894 1.61068 15.4554 2.06117 15.4554 2.06117C15.4554 2.06117 14.7309 2.57423 14.0869 3.02473L12.799 4.05443L11.8331 4.95542C11.3501 5.40591 10.6256 6.11383 10.6256 6.11383C10.6256 6.11383 9.49871 5.72769 7.8888 5.21284C6.2789 4.69799 4.99097 4.50671 4.99097 4.50671Z" fill="#EDD3CA"/>
          <path d="M12.7988 7.01485C11.2694 6.28118 10.6256 6.11383 10.6256 6.11383C10.6256 6.11383 11.3498 5.27902 12.8793 3.92753C14.4087 2.57605 15.6161 1.99684 15.6161 1.99684C15.6161 1.99684 17.226 1.22456 19.5604 0.580995C21.8947 -0.0625687 23.7461 0.00178768 23.7461 0.00178768C23.7461 0.00178768 24.3096 0.00178766 24.8731 0.259213C25.4365 0.516639 25.5975 0.838421 25.5975 0.838421C25.5975 0.838421 25.839 1.1602 25.7585 2.25426C25.678 3.34832 25.7585 4.31188 25.7585 4.31188C25.7585 4.31188 23.7461 4.5693 20.2848 5.85643C16.8235 7.14356 15.1331 8.17326 15.1331 8.17326C15.1331 8.17326 14.3282 7.74851 12.7988 7.01485Z" fill="#FCEDE8"/>
          </svg>`
      }
    },
    props: {
      data: {
        type: [Object, Array],
      },
      title: {
        type: String,
      },
      buttons: {
        type: [Object, Array],
        default: () => ([
          {
            label: 'shop',
            to: '/flowers-for-delivery-chichester',
            type: 'primary'
          },
          {
            label: 'weddings',
            to: '/chichester-wedding-flowers',
            type: 'secondary'
          }
        ]),
      },
      images: {
        type: Array,
        default: null
      },
      animateProp: {
        type: Boolean,
        default: true
      }
    },
    computed: {
      setCurrentImage() {
        const ImagesArray = this.data.Images.map((image) => {
          return {
            to: image.link,
            src: image.image.data.attributes.url,
            alt: image.image.data.attributes.caption
          }
        });
        return ImagesArray[Math.floor(Math.random()*ImagesArray.length)];
      },
      titleMarked() {
        return this.data.Title;
      },
      navigation() {
        return this.data.Navigation;
      }
    },
    mounted() {
      useParallax('data-hero-scroll', 1, true);
    },
    unmounted() {
      useParallax('data-hero-scroll', 1, false);
    }
  });
</script>
