<template>
  <span>
      {{text}}
  </span>
</template>
<script>
  import { defineComponent } from '@vue/composition-api'

  export default defineComponent({
    name: 'EarliestDelivery',
    props: {
      text: {
        type: String,
        default: 'Pssst.. we offer same-day delivery within ~10 miles of Chichester. Order by 10am!'
      }
    },
    setup() {
      let date = '';
      const today = new Date();
      const time = today.getHours();

      time > 10 ? date = 'tomorrow' : date = 'today';

      return {
        date
      }
    }
  })
</script>
