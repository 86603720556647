<template>
  <img src="homepage/home_watermark.svg" alt="Hedge Rose Watermark"/>
</template>
<script>
import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'HomeWatermark',
});
</script>
