
<template>
  <div class="flex flex-wrap md:flex-nowrap md:grid-cols-2 w-full md:h-[500px]" data-scroll :class="backgroundColor">
    <div class="w-full relative" :class="reverse ? 'md:order-last' : 'md:order-first'" ref="content">
      <div class="px-8 py-7 h-full w-full flex items-start justify-center flex-col max-w-md m-auto">
        <p v-if="topText" class="pb-4 text-primary animate" data-animate-type="animate-fade-in"
        data-scroll data-scroll-speed="0.5" data-scroll-delay="0.5">{{topText}}</p>
        <div  data-scroll data-scroll-speed="0.75" data-scroll-delay="1">
          <h3 v-if="titleText" class="text-[24px] md:text-2xl text-blackSecondary font-bold pb-6 animate" data-animate-type="animate-fade-in">{{titleText}}</h3>
        </div>
        <p v-if="textMain" data-scroll data-scroll-speed="0.5" data-scroll-delay="1.1" class="text-blackSecondary pb-4 md:max-w-[488px] animate" data-animate-type="animate-fade-in">{{textMain}}</p>
        <div v-if="button" class="mb-8 mt-6" data-scroll data-scroll-speed="0.5" data-scroll-delay="0.5">
          <PrimaryButton v-if="button.link" :to="button.link" class="animate" data-animate-type="animate-fade-in">{{button.text}}</PrimaryButton>
        </div>
      </div>
      <img src="icons/two-col-flower.svg" :alt="titleText" width="212" height="529" class="absolute bottom-0 right-0 visible hidden md:block animate opacity-30" data-animate-type="animate-scale-in" :animate="false"/>
    </div>
    <div class="w-full overflow-hidden animate" data-animate-type="animate-fade-in" ref="image">
      <img v-if="images" :alt="topText" :src="images.length == 1 ? images[0] : tempImage" class="w-full h-full aspect-square object-cover" />
    </div>
  </div>
</template>
<style lang="scss" scoped>
  .animate {
    opacity: 0;
  }
</style>
<script>
  import { defineComponent } from '@nuxtjs/composition-api';
  import PrimaryButton from '~/components/Headless/PrimaryButton.vue';
  import SvgImage from '~/components/General/SvgImage.vue';
  import { animateOnScroll } from '~/helpers/headless/helpers';
  import { setSirvImage } from '~/helpers/headless/helpers';

  export default defineComponent({
    name: "HeadlessTwoColBanner",
    components: {
      PrimaryButton,
      SvgImage
    },
    props: {
      backgroundColor: {
        type: String,
        default: 'bg-secondaryLight'
      },
      topText: {
        type: String,
        default: null
      },
      titleText: {
        type: String,
        default: null
      },
      textMain: {
        type: String,
        default: null
      },
      button: {
        type: Object,
        default: null
      },
      images: {
        type: Array,
        default: []
      },
      reverse: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        tempImage: '',
        setSirvImage
      }
    },
    methods: {
      // If array is only 1 return that image, if the array is greater than 1 return first image and loop through arrary at a set interval
      updateImage(array) {
        let i = 0;
        setInterval(() => {
          const image = array[i];
          i++;
          if (i >= array.length) {
            i = 0;
          }
          this.tempImage = image;
        }, 1000);
      },
      setSameHeight(target, victim) {
        victim.style.height = target.offsetHeight + 'px';
      }
    },
    mounted() {
      this.updateImage(this.images);
      if (this.$device.isMobileOrTablet && this.images.length > 1) this.setSameHeight(this.$refs.content, this.$refs.image);
      animateOnScroll();
    }
  });
</script>
